import http from '@/services/http/http';
import catch_http_error from '@/services/http/catch_http_error'
import validation_errors from '@/services/http/validation_errors'

export async function list() {
  const url = 'api/v1/payment_accounts';
  const res = await http.get(url);
  return res.data;
}

export async function create({name, description}) {
  try {
    const res = await http.post('api/v1/payment_accounts', {payment_account: { name, description }});
    return res
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422) {
      if (e.response.data.message)
        catch_http_error(e)
      else
        return Promise.reject(validation_errors(e))
    } else {
      catch_http_error(e)
    }
  }
}

export async function update({id, name, description}) {
  try {
    const res = await http.put('api/v1/payment_accounts/'+id, {payment_account: { name, description }});
    return res
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422) {
      if (e.response.data.message)
        catch_http_error(e)
      else
        return Promise.reject(validation_errors(e))
    } else {
      catch_http_error(e)
    }
  }
}

export async function destroy(id) {
  try {
    const res = await http.delete('api/v1/payment_accounts/'+id);
    return res
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422) {
      if (e.response.data.message)
        catch_http_error(e)
      else
        return Promise.reject(validation_errors(e))
    } else {
      catch_http_error(e)
    }
  }
}
<template>
  <dashboard-layout>
    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">
        My Payment Accounts
      </v-toolbar-title>
    </template>

    <template slot="content">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="12">
            <v-card elevation="2" :loading="loading">
              <v-card-text>
                <p>This will be shown in your subscriber's bill.</p>
                <v-tabs v-model="account" align-with-title>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab v-for="(account, i) in accounts" :key="i">
                    {{!account.id ? '+ Add New' : account.name}}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="account">
                  <v-tab-item
                    v-for="(account, e) in accounts"
                    :key="e"
                  >
                    <v-card flat>
                      <v-card-text>
                        <payment-account :account="account" @refreshList="load"></payment-account>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </dashboard-layout>
</template>

<script>
  import { list } from '@/services/http/payment_account';
import PaymentAccount from '@/views/dashboard/payment-accounts/PaymentAccount'

export default {
  name: "PaymentAccounts",
  components: {
    PaymentAccount
  },
  data: ()=>({
    loading: true,
    account: null,
    new_account: {name: '', id: 0},
    accounts: []
  }),
  created() {
    this.load()
  },
  methods: {
    load(){
      return list().then(accounts => {
        this.accounts = []
        this.accounts = accounts
      }).finally(()=>{
        this.loading = false
        this.accounts.push(this.new_account)
      })
    },
  }
};
</script>

<template>
  <v-card tile :loading="loading">
    <div style="padding: 30px;" elevation="2">
      <form ref="form" @submit.prevent="submit">
        <v-text-field
          type="text"
          label="Name"
          v-model="name"
          placeholder="e.g. GCash"
          required
        >
        </v-text-field>
        <v-textarea
          label="Payment Instructions"
          v-model="description"
          required
          placeholder="Input instructions on how to pay with account number and/or account name. You can enter HTML code."
          auto-grow
          rows="10"
        >
        </v-textarea>

        <v-btn
          type="submit"
          color="primary"
          style="text-transform: none; margin-top: 10px;"
          :disabled="loading"
          :loading="loading"
        >
          Save
        </v-btn>

        <span v-if="account.id">
          <v-btn
            type="button"
            color="red"
            style="text-transform: none; margin-top: 10px;margin-left:10px;"
            @click="destroy"
          >
            Delete
          </v-btn>        
        </span>
      </form>
    </div>
  </v-card>
</template>

<script>
  import { update, create, destroy } from '@/services/http/payment_account';
  export default {
    name: "PaymentAccount",
    data: () => ({
      name: '',
      description: '',
      loading: true
    }),
    props: ['account'],
    created(){
      this.load()
    },
    computed: {
    },
    methods: {
      async load(){
        if (this.account) {
          this.name = this.account.name
          this.description = this.account.description
        }
        this.loading = false
      },
      async submit(){
        if(!this.name || !this.description) return
        this.loading = true
        try {
          var {name, description} = this
          var {id} = this.account || {}
          console.log({ update, create, destroy })
          if (id) {
            await update({id, name, description})
          } else {
            await create({name, description})
          }
        } catch(e) {
          console.log(e)
        } finally {
          this.loading = false
          this.$emit("refreshList")
        }
      },
      async destroy(){
        var {id} = this.account || {}
        if (!id) return
        const yn = await this.$confirm('Are you sure you want to delete this Payment Account?', {title: 'Confirm'})
        if (!yn) return
        try {
          await destroy(id)
        } catch(e) {
          console.log(e)
        } finally {
          this.loading = false
          this.$emit("refreshList")
        }
      }
    }
  }
</script>